.state-dialog-body{
  max-height: 470px;
  min-height: 470px;
  overflow-y: scroll;

  &:hover{
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--custom-white);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4E6F1 ;
    border-radius: 20px;
    border: 1px solid var(--custom-white);
  }

  .single-state-row{
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;

    &:hover{
      background-color: #D4E6F1 ;
    }
  }
}