.navbar {
    height: 60px;
    position: fixed;
    background-color: white;
    top: 0;
    overflow: hidden;
    width: 100%;
    z-index: 10;

    .navbar-wrapper {
        display: flex;
        justify-content: end;
        width: 100%;
        overflow: hidden;
        margin-right: 250px;
        padding-right: 35px;
        padding-left: 35px;

        .icon-container{
            position: relative;
            cursor: pointer;
            margin-top: 5px;
            margin-right: 20px;

            .icon{
                font-size: 27px;
            }

            .counter{
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #A93226;
                color: white;
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -3px;
                right: -3px;
            }
        }


        .avatar{
            border-radius: 50%;
            height: 35px;
            width: 35px;
            cursor:pointer;
            overflow: hidden;
        }

        .profile-menu {
            display: none;
            position: fixed;
            background-color: #f9f9f9;
            min-width: 160px;
            top: 50px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 80;
        }

        .profile-menu-visible {
            display: block;
        }

        /* Links inside the dropdown */
        .profile-menu span {
            float: none;
            cursor: pointer;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            text-align: left;
        }

        /* Add a grey background color to dropdown links on hover */
        .profile-menu span:hover {
            background-color: #ddd;
        }

        .avatar:focus + .profile-menu{
            display: block;
        }
    }
}