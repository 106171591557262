.show-tab{
  display: flex;
}

.hide-tab{
  display: none;
}

.status{
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.Completed{
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
}

.Pending,  .GoingToPickup, .GoingToDeliver{
  color: #916c2e;
  background-color: #fcf0db;
  border-color: #fbe9c9;
}

.payment-status{
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  width: 150px;
}

.Credit{
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
}

.Debit{
  color: #924040;
  background-color: #fde1e1;
  border-color: #924040;
}