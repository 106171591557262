th, td {
  border-bottom: 1px dashed #BFC9CA;
  border-collapse: collapse;
}

th, td {
  padding: 15px 10px 15px 10px;
}

.search-input{
  border-style: hidden;
  background-color: transparent;
  width: 100%;

  &:focus{
    border-style: hidden;
    outline: none;
  }
}

.chat-list-container{
  margin-top: 20px;
  max-height: 470px;
  min-height: 470px;
  padding-right: 5px;
  overflow-y: scroll;

  &:hover{
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--custom-white);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4E6F1 ;
    border-radius: 20px;
    border: 1px solid var(--custom-white);
  }

  .active{
    background-color: #D4E6F1 ;
  }

  .single-chat-list-container{
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;

    .active{
      background-color: #D4E6F1 ;
    }

    &:hover{
      background-color: #D4E6F1 ;
    }

    .user-profile-image{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .info-container{
      width:85%;

      .user-name{
        font-size: 15px;
        font-weight: bold;
        color: #566573;
      }

      .time{
        font-size: 10px;
      }

      .message{
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width:80%
      }

      .counter{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #A93226;
        color: white;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.chat-detail-container{
  .chat-detail-header{
    border-bottom: 1px solid #E5E8E8;
    padding-bottom: 5px;

    .chat-detail-data-container{
      display: flex;
      flex-direction: column;
      flex: 1;

      .name{
        font-size: 15px;
        font-weight: bold;
        color: #566573;
      }

      .phone{
        font-size: 13px;
        margin-top: -2px;
      }
    }
  }

  .chat-detail-body{
    padding-bottom: 20px;
    margin-top: 20px;
    max-height: 403px;
    min-height: 403px;
    overflow-y: scroll;

    &:hover{
      overflow-y: scroll;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--custom-white);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #D4E6F1 ;
      border-radius: 20px;
      border: 1px solid var(--custom-white);
    }
  }
}
