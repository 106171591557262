//@font-face {
//  font-family: 'DM Serif Display Regular';
//  font-style: normal;
//  font-weight: normal;
//  src: local('DM Serif Display Regular'), url('fonts/DMSerifDisplay-Regular.woff') format('woff');
//}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #74788d !important;
}

.home {
  display: flex;
  overflow: hidden;
}

.main {
  margin-left: 250px;
  margin-top: 60px;
  width: 100%;
  overflow: auto;
}

.page {
  background-color: #EAF2F8;
  min-height: 100vh;
}

a{
  text-decoration: none;
}