.profile-tab{
  padding: 5px 10px;
  cursor: pointer;

  &:hover{
    color: #252229;
    border-bottom: 2px solid #252229;
  }
}

.active-profile{
  border-bottom: 2px solid #EE2463;
  color: #EE2463;
}

.single-plan{
  border-radius: 6px;
  background-color: #EBF5FB;
  height: 550px;
  padding: 15px;
  border: 1px solid #D6EAF8;
}

.active-plan-button{
  background-color: #008561;
  border-radius: 6px;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;

  &:hover{
    background-color: #45B39D;
  }
}

.current-plan-button{
  background-color: #F2F3F4;
  border-radius: 6px;
  border: 1px solid #D7DBDD;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: default;
}

.change-password-button{
  background-color: #F2F3F4;
  border-radius: 6px;
  border: 1px solid #D7DBDD;
  padding: 7px 10px;
  text-align: center;
  cursor: pointer;
}

.rate-sections {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 32px;
  margin-bottom: 80px;
  padding: 0 24px;

  .rate-card {
    display: flex;
    column-gap: 20px;
    border: 1px solid #D6EAF8;
    padding: 14px 24px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
      transform: scale(1.05);
      transition: all ease-in-out 500ms;
    }

    .rate-left {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      
      .rate-title {
        font-size: 18px;
        font-weight: 500;
      }

      .rate-desc {
        font-size: 12px;
      }

      .sample-btn {
        position: relative;
        z-index: 2;
        color: #EE2463;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .rate-right {
      padding-top: 10px;
      .rate-card-icon {
        font-size: 32px;
        font-weight: 500;
      }
    }

    .rate-input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .cancel-doc {
      color: rgb(255, 104, 104);
      font-size: 24px;
      font-weight: 500;
      position: absolute;
      z-index: 2;
      cursor: pointer;
      top: 8px;
      right: 8px;
    }
  }

  .rate-card-active {
    border: 1px solid #EE2463;
  }
}