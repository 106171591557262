.shipments-container {
    .shipments-table{
        th, td {
            border-bottom: 1px dashed #BFC9CA;
            border-collapse: collapse;
        }

        th, td {
            padding: 15px 10px 15px 10px;
        }
    }

    tr {
        cursor: pointer;

        &:hover {
            background-color: aliceblue;
        }
    }
}

