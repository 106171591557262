.query-result-container{
  position: relative;
}

.query-result {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 80;
}

.result-visible {
  display: flex;
  flex-direction: column;
}

/* Links inside the dropdown */
.result-visible div {
  float: none;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.result-visible div:hover {
  background-color: #ddd;
}
