.suggestions-field{
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
  display: none;

  .single-suggestion{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 5px;
    padding-left: 5px;

    &:hover{
      background-color: #ddd;
    }
  }
}

.suggestions-field-visible{
  display: block;
}