.user-menu-container{
  position: relative;
}

.user-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  top: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 80;
}

.user-menu-visible {
  display: block;
}

/* Links inside the dropdown */
.user-menu span {
  float: none;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.user-menu span:hover {
  background-color: #ddd;
}

.avatar:focus + .user-menu{
  display: block;
}

.Rejected, .Cancelled{
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.PendingApproval{
  color: #464855;
  background-color: #e3e4e8;
  border-color: #d5d7dd;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.PendingPickup{
  color: #37458b;
  background-color: #dee3fa;
  border-color: #ced5f8;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.Delivered{
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.PickedUp,  .GoingToPickup, .GoingToDeliver{
  color: #916c2e;
  background-color: #fcf0db;
  border-color: #fbe9c9;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.shipment-class{
  width: 30px;
  height: 30px;
  padding: 3px;
  background-color: #D4E6F1;
  color: #2471A3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.account-class{
  width: 40px;
  height: 40px;
  background-color: #FDEBD0;
  color: #E67E22;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.admin-activities-container{
  min-height: 650px;
  max-height: 650px;
  overflow-y: hidden;
}